body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);

  background: var(--bgColor);
}

* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  overflow-x: hidden;
}

@keyframes strikeAnimation {
  from {
    width: 0%;
  }
  to {
    width: 89%;
  }
}

@keyframes lineContainerFadeAway {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--translucent);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 50px;
}

iframe {
  border: none !important;
}

.chrome-picker {
  width: 100% !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color);
  opacity: 0.8; /* Firefox */
}

/* Material UI */

.MuiInputBase-root:before {
  display: none !important;
}

.MuiInputBase-root {
  overflow: hidden !important;
}
